import React, { useState,useEffect} from 'react';
import Cookies from 'js-cookie';
import ndoto from '../assets/ndoto.jpg';
import './Login.css';
import logo from '../assets/logo.png'
import logo1 from '../assets/beauty_logo.png'
import logo_banner from '../assets/logo-banner.jpg'
import fashion from '../assets/fasshionnew.png'
import luxury from '../assets/luxurynew.png'
import comedy from '../assets/loltv.png'
import ndotoStream from '../assets/ndotostream.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { useSelector, useDispatch } from 'react-redux';
import {
  setPhoneNumber,
  setUserExists,
  setUserData,
  setIsActive,
  setLoading,
} from '../slices/authSlice';
import { setCategoryName } from '../slices/categorySlice';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import MainLoader from './MainLoader';
import lolbanner from '../assets/lol-bannner.jpg'
import fashionbanner from '../assets/fashion-banner.jpg'
import luxurybanner from '../assets/luxury-banner.jpg'
import sportsbanner from '../assets/sports-banner.png'
import businessbanner from '../assets/business-banner.jpg'
import wohbanner from '../assets/woh-banner.png'
import topicsbanner from '../assets/topics-banner.png'

function LoginForm({isBeautySubdomain}) {
  // console.log("prcoess env", process.env.REACT_APP_PUBLIC_URL)
  const categoryname = useSelector((state) => state.category.categoryname);
  console.log("name", categoryname)
  const dispatch = useDispatch();
  const phoneNumber = useSelector((state) => state.auth.phoneNumber);
  const loading = useSelector((state) => state.auth.loading);
  const userExists = useSelector((state) => state.auth.userExists);
  const userData = useSelector((state) => state.auth.userData);
  const isActive = useSelector((state) => state.auth.isActive);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [logo,setLogo]=useState([])
  const [error, setError] = useState(false)
  const base_url = process.env.REACT_APP_PUBLIC_URL;
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setInitialLoading(false); 
    }, 3000);
  }, []);

  // const subdomain = window.location.hostname.split('.')[0];
  const subdomain='beauty'
  const fetchLogo = async () => {

    try {
      const response = await axios.get(`/api/logos/${subdomain}`);
      setLogo(response.data);
 
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };

  useEffect(()=>{
    fetchLogo()
  },[subdomain])
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setButtonDisabled(true);
    try {
      const response = await fetch(`/api/subscription/check?ani=${phoneNumber}&service=${subdomain}`);
      const data = await response.json();
      console.log("data number", data);
      const active = data.user.STATUS;
      const fetchedServiceType  = data.serviceType; // Get the service type from the API response
    
      const ani = data.user.ani;

      Cookies.set('ani', ani);
      const aniookie = Cookies.get('ani');
   
      if (data.exists) {
        dispatch(setLoading(true));
      
        dispatch(setUserExists(true));
        dispatch(setUserData(data.user));
        dispatch(setCategoryName(fetchedServiceType))
        dispatch(setIsActive(active));
        dispatch(setLoading(false));
        setButtonClicked(true);
        setTimeout(() => {

          navigate(`/redirect/${subdomain}?msisdn=${phoneNumber}&result=Active`);
          setButtonDisabled(false);
          setButtonClicked(false);
        }, 4000);

        setTimeout(() => {
          navigate('/login');
        }, 12 * 60 * 60 * 1000);
      } else {
        toast.success
          ("User doesn't exists !", {
            position: toast.POSITION.TOP_RIGHT
          });
          setLoading(false)

        dispatch(setUserExists(false));
        dispatch(setUserData(null));
        dispatch(setLoading(false));

      }
    } catch (error) {
      toast.error
        ("User doesn't exists ", {
          position: toast.POSITION.TOP_RIGHT
        });
        setButtonDisabled(false);
        setButtonClicked(false);
      navigate('/')
    }
  };


  return (
    <>
<div className='h-full'>
{initialLoading ? <MainLoader service={subdomain}/>:
  <>
  <div className='vt-login-main h-full  bg-black '>
          <div className=''>
  
     
     {subdomain==='videos' ? 
        <img class="h-[70px] w-[200px] mx-auto" src={ndotoStream} alt="image description" />
     : <>
      {logo.length > 0 &&
      <img class="h-[70px] w-[200px] mx-auto" src={logo[0].logourl} alt="image description" />
      }</>}
           
  
          </div>
          <div className="max-w-sm container mx-auto mt-4   bg-[#0a0b0c]  border-gray-400 rounded-lg shadow-lg ">
  
  
          {subdomain==='beauty' &&
            <img className="rounded-t-lg h-[280px] object-fit  w-full" src={logo_banner} alt="" />
          }
       {subdomain==='fashion' &&
            <img className="rounded-t-lg h-[280px] object-fit  w-full" src={fashionbanner} alt="" />
          }
  
  {subdomain==='nvod' &&
            <img className="rounded-t-lg h-[280px] object-fit  w-full" src={ndoto} alt="" />
          }
          {subdomain==='luxury' &&
            <img className="rounded-t-lg h-[280px] object-fit  w-full" src={luxurybanner} alt="" />
          }
            {subdomain==='sports' &&
            <img className="rounded-t-lg h-[280px] object-fit  w-full" src={sportsbanner} alt="" />
          }
          {subdomain==='comedy' &&
            <img className="rounded-t-lg h-[280px] object-fit  w-full" src={lolbanner} alt="" />
          }
           {subdomain==='business' &&
            <img className="rounded-t-lg h-[280px] object-fit  w-full" src={businessbanner} alt="" />
          }
           {subdomain==='woh' &&
            <img className="rounded-t-lg h-[280px] object-fit  w-full" src={wohbanner} alt="" />
          }
  
  {subdomain==='topics' &&
            <img className="rounded-t-lg h-[280px] object-fit  w-full" src={topicsbanner} alt="" />
          }
  {subdomain==='videos' &&
            <img className="rounded-t-lg h-[280px] object-fit  w-full" src={businessbanner} alt="" />
          }
  
            <div className="p-5">
              <form onSubmit={handleSubmit}>
                <a href="#">
  
                  <h5 className="mb-2 text-xl mt-1 font-medium tracking-tight text-center text-gray-200 dark:text-white">
                    Login With Your Registered Number
                  </h5>
                </a>
                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="block mb-2 mt-5 text-md font-medium text-gray-200 dark:text-white"
                  >
                    Your Number
                  </label>
                  <input
                    type="text"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Enter Registered Number"
                    required
                    value={phoneNumber}
                    onChange={(e) => {
                      const inputPhoneNumber = e.target.value;
  
                      // Remove leading zero if present
                      const sanitizedPhoneNumber = inputPhoneNumber.replace(/^(27|0)+/, '');
  
                      dispatch(setPhoneNumber(sanitizedPhoneNumber));
                    }}
                    autoComplete="off" 
                    autoCorrect="off" 
                    autoCapitalize="off" 
                  // onChange={(e) => dispatch(setPhoneNumber(e.target.value))}
                  />
  
  
                  <div className="flex justify-center">
                    <button 
                 disabled={buttonClicked}
                      type="submit"
                      className="text-white text-md bg-red-600 flex mt-5 hover:bg-red-800 font-medium rounded-lg px-6 py-2.5 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none "
                    >
                      
                      {buttonDisabled ? <Loading  className="py-3  "/> : 'Login'} 
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
  </>}
</div>

    </>
  );
}

export default LoginForm;
